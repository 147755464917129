<template>
  <el-container class="shopList">
    <el-header class="box bb add aic">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>报账记录管理</el-breadcrumb-item>
        <el-breadcrumb-item>报账记录列表</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-main class="p15 pt0">
      <div class="pt20" style="text-align:left;">
        <el-form :inline="true" :model="page" class="demo-form-inline">
          <el-form-item>
            <el-input
              placeholder="报账人"
              v-model="page.keyword"
              clearable
              size="medium"
              @clear="search"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="timeData"
              type="daterange"
              size="medium"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="dataChange"
              @clear="search"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              type="primary"
              size="medium"
              @click="search"
              >查询</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-download"
              type="success"
              size="medium"
              @click="downloadExcel(1)"
              >导出</el-button
            >
          </el-form-item>
        </el-form>
      </div>

      <!-- <el-row class="box search mt20">
        <el-form :inline="true" :model="page" class="demo-form-inline">
          <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-input
                placeholder="报账人"
                v-model="page.keyword"
                clearable
                class="input-with-select"
                @clear="search"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5" class="mr10">
            <el-form-item size="medium">
              <el-date-picker
                style="width: 100%"
                v-model="timeData"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @clear="search"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <div class="df">
            <el-button
              icon="el-icon-search"
              type="primary"
              style="height: 36px;border:none"
              class="mr10"
              @click="search"
              >查询</el-button
            >
            <el-button
              style="height: 36px;border:none;margin-left:0"
              class="mr10"
              icon="el-icon-download"
              type="success"
              @click="downloadExcel(1)"
              >导出</el-button
            >
          </div>
        </el-form>
      </el-row> -->

      <el-table
        :data="tableData"
        border
        style="width: 100%"
        :header-cell-style="{
          background: '#eef1f6',
          color: '#606266',
        }"
      >
        <el-table-column type="index" align="center" label="序号" width="60" />

        <el-table-column prop="userName" align="center" label="用户名" />
        <el-table-column prop="totalCount" align="center" label="报账总次数" />
        <el-table-column
          prop="totalMoney"
          align="center"
          label="报账总金额(元)"
        />
        <el-table-column prop="passCount" align="center" label="审核通过次数" />
        <el-table-column
          prop="passMoney"
          align="center"
          label="已报账金额(元)"
        />
        <el-table-column
          prop="noPassCount"
          align="center"
          label="审核未通过次数"
        />
        <el-table-column
          prop="noPassMoney"
          align="center"
          label="未通过的金额(元)"
        />
        <el-table-column
          prop="noCount"
          align="center"
          label="通过未打款的次数"
        />
        <el-table-column
          prop="noMoney"
          align="center"
          label="通过未打款的金额(元)"
        />
        <el-table-column label="操作" align="center" width="180">
          <template slot-scope="scope">
            <!-- <el-button
              type="primary"
              size="mini"
              @click="handleDetail(scope.row)"
              ></el-button
            > -->
            <el-button
              type="text"
              class="disib blue"
              size="mini"
              @click="handleDetail(scope.row)"
              >查看明细</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="i-page disib mt20">
        <el-pagination
          background
          :page-size="10"
          layout="total,prev, pager, next"
          :total="total"
          :current-page="page.page"
          @current-change="pageChange"
        >
        </el-pagination>
      </div>
    </el-main>
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="50%">
      <el-form :inline="true" :model="detailPage" class="demo-form-inline">
        <el-form-item>
          <el-input
            placeholder="类型、备注"
            v-model="detailPage.keyword"
            clearable
            @clear="searchDetail"
            size="medium"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            style="width: 100%"
            v-model="timeDataDetail"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @clear="searchDetail"
            @change="dataDetailChange"
            size="medium"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="detailPage.status"
            placeholder="状态"
            size="medium"
            @visible-change="selectChange"
          >
            <el-option
              v-for="item in statusType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            icon="el-icon-search"
            type="primary"
            size="medium"
            @click="searchDetail"
            >&emsp;查询&emsp;</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            icon="el-icon-download"
            type="success"
            size="medium"
            @click="downloadExcel(0)"
            >导出明细</el-button
          >
        </el-form-item>
      </el-form>
      <!-- <el-row class="box search mt20">
          <el-form :inline="true" :model="detailPage" class="demo-form-inline">
          <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-input
                placeholder="类型、备注"
                v-model="detailPage.keyword"
                clearable
                class="input-with-select"
                @clear="searchDetail"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" class="mr10">
            <el-form-item size="medium">
              <el-date-picker
                style="width: 100%"
                v-model="timeDataDetail"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @clear="searchDetail"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-select
                v-model="detailPage.status"
                placeholder="状态"
                @visible-change="selectChange"
              >
                <el-option
                  v-for="item in statusType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3" class="tl mr10">
            <el-form-item size="medium">
              <div class="df">
                <el-button
                  icon="el-icon-search"
                  type="primary"
                  @click="searchDetail"
                  >&emsp;查询&emsp;</el-button
                >
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="3" class="tl mr10">
            <el-form-item size="medium">
              <div class="df">
                <el-button
                  icon="el-icon-download"
                  type="success"
                  @click="downloadExcel(0)"
                  >导出明细</el-button
                >
              </div>
            </el-form-item>
          </el-col>
        </el-form>
        </el-row> -->

      <el-table
        :data="detailList"
        ref="expandTable"
        @expand-change="expandChange"
        style="width: 100%"
        :header-cell-style="{
          background: '#eef1f6',
          color: '#606266',
        }"
      >
        <el-table-column type="expand" label="更多信息" width="90">
          <template slot-scope="props">
            <div class="df" style="padding: 0 20px; flex-wrap: wrap">
              <div class="expand-row">
                <div class="expand-label">备注</div>
                <div>
                  <span :style="props.row.remark ? '' : 'color:#ccc'">
                    {{ props.row.remark ? props.row.remark : "未填写" }}
                  </span>
                </div>
              </div>

              <div class="expand-row">
                <div class="expand-label">费用证明</div>
                <div class="df" v-if="props.row.imgList">
                  <div
                    class="img-box"
                    v-for="(item, index) in JSON.parse(props.row.imgList)"
                    :key="index"
                    @click="previewImg(item.url)"
                  >
                    <img
                      :src="item.url"
                      alt=""
                      style="height: 100%; width: 100%; object-fit: cover"
                    />
                  </div>
                </div>
                <div style="color: #ccc" v-else>未提交</div>
              </div>

              <div class="expand-row">
                <div class="expand-label">电子发票</div>
                <div
                  v-if="props.row.fileList"
                  class="df"
                  style="flex-wrap: wrap"
                >
                  <div
                    class="file-box df aic"
                    v-for="(item, index) in JSON.parse(props.row.fileList)"
                    :key="index"
                    @click="downloadFile(item.url)"
                  >
                    <span
                      style="margin-right: 8px; font-size: 24px; color: #fff"
                    >
                      <i class="el-icon-document"></i>
                    </span>
                    <span class="file-name">{{
                      item.fileName.length > 14
                        ? item.fileName.slice(0, 5) +
                          "..." +
                          item.fileName.slice(-9)
                        : item.fileName
                    }}</span>
                  </div>
                </div>
                <div style="color: #ccc" v-else>未提交电子发票</div>
              </div>
              <div class="expand-row">
                <div class="expand-label">打款凭证</div>
                <div class="df" v-if="props.row.dkImgList">
                  <div
                    class="img-box"
                    v-for="(item, index) in JSON.parse(props.row.dkImgList)"
                    :key="index"
                    @click="previewImg(item)"
                  >
                    <img
                      :src="item"
                      alt=""
                      style="height: 100%; width: 100%; object-fit: cover"
                    />
                  </div>
                </div>
                <div style="color: #ccc" v-else>未提交</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="类型" align="center" width="180">
        </el-table-column>
        <el-table-column
          prop="money"
          label="报账金额(元)"
          align="center"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="auditStatus"
          label="状态"
          align="center"
          width="180"
        >
          <template slot-scope="scope">
            <span :style="auditStatus[scope.row.auditStatus].color">
              {{ auditStatus[scope.row.auditStatus].text }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="tallyTime"
          label="报账日期"
          align="center"
          width="180"
        >
          <template slot-scope="scope">
            {{ scope.row.tallyTime }}
          </template>
        </el-table-column>
        <el-table-column
          prop="dkCardName"
          label="打款账户"
          align="center"
          width="180"
        >
          <template slot-scope="scope">
            <span :style="scope.row.dkCardName ? '' : 'color:#ccc'">
              {{
                scope.row.dkCardName ? scope.row.dkCardName : "未选择打款账户"
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="skCardName"
          label="收款账户"
          align="center"
          width="180"
        >
          <template slot-scope="scope">
            <span :style="scope.row.skCardName ? '' : 'color:#ccc'">
              {{
                scope.row.skCardName ? scope.row.skCardName : "未选择收款账户"
              }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div class="i-page disib mt20">
        <el-pagination
          background
          :page-size="10"
          layout="total,prev, pager, next"
          :total="detailTotal"
          :current-page="detailPage.page"
          @current-change="detailPageChange"
        >
        </el-pagination>
      </div>
    </el-dialog>
    <el-dialog title="图片" :visible.sync="dialogImg" width="800px">
      <img :src="previewImgUrl" width="800px" alt="" />
    </el-dialog>
  </el-container>
</template>
<script>
import { local } from "@/util/util";
import api from "@/util/extra-api";
import dayjs from "dayjs";

export default {
  data() {
    return {
      dialogVisible: false,
      dialogImg: false,
      tableData: [],
      page: {
        page: 1,
        limit: 10,
        keyword: "",
        startTime: "",
        endTime: "",
      },
      timeData: "",
      timeDataDetail: "",
      total: 0,
      companyId: null,
      detailList: [],
      detailPage: {
        page: 1,
        limit: 10,
        keyword: "",
        startTime: "",
        endTime: "",
        user: null,
        status: -1,
      },
      detailTotal: 0,
      statusType: [
        {
          label: "全部",
          value: -1,
        },
        {
          label: "待审核",
          value: 0,
        },
        {
          label: "已通过",
          value: 1,
        },
        {
          label: "已打款",
          value: 2,
        },
        {
          label: "已拒绝",
          value: 3,
        },
      ],
      auditStatus: {
        audit: {
          text: "待审核",
          color: "color:#ccc",
        },
        reimbursed: {
          text: "已打款",
          color: "color:#67C23A",
        },
        approved: {
          text: "已通过",
          color: "color:#409EFF",
        },
        rejected: {
          text: "已拒绝",
          color: "color:#F56C6C",
        },
      },
      dialogTitle: "",
      previewImgUrl: "",
      oldStatus: null,
    };
  },
  mounted() {
    this.companyId = Number(local.get("company_id"));
    this.getList();
  },
  watch: {
    value1(val) {
      if (val) {
        this.page.startTime =
          val[0].getFullYear() +
          "-" +
          (val[0].getMonth() + 1 < 10
            ? "0" + (val[0].getMonth() + 1)
            : val[0].getMonth() + 1) +
          "-" +
          (val[0].getDate() < 10 ? "0" + val[0].getDate() : val[0].getDate());
        this.page.endTime =
          val[1].getFullYear() +
          "-" +
          (val[1].getMonth() + 1 < 10
            ? "0" + (val[1].getMonth() + 1)
            : val[1].getMonth() + 1) +
          "-" +
          (val[1].getDate() < 10 ? "0" + val[1].getDate() : val[1].getDate());
        // console.log(this.page);
      } else {
        this.page.startTime = "";
        this.page.endTime = "";
        this.search();
        // console.log("清除");
      }
    },
  },
  methods: {
    dataDetailChange(value){
      // console.log(value);
      if(!value){
        this.timeDataDetail=''
        this.searchDetail()
      }
    },
    dataChange(value){
      // console.log(value);
      if(!value){
        this.timeData=''
        this.search()
      }
    },
    selectChange(status) {
      if (status) {
        this.oldStatus = this.detailPage.status;
      } else {
        if (this.oldStatus !== this.detailPage.status) {
          this.searchDetail();
        }
      }
    },
    downloadExcel(type) {
      if (type) {
        api
          .fileget(`/v1/pc/tally/export1?companyId=${this.companyId}`, {
            startTime: this.page.startTime,
            endTime: this.page.endTime,
          })
          .then((res) => {
            if (res.code == 200) {
            }
          });
      } else {
        api
          .fileget(`/v1/pc/tally/export2?companyId=${this.companyId}`, {
            startTime: this.detailPage.startTime,
            endTime: this.detailPage.endTime,
            user: this.detailPage.user,
            status: this.detailPage.status,
          })
          .then((res) => {
            if (res.code === 200) {
            }
          });
      }
    },
    downloadFile(url) {
      window.open(url);
    },
    previewImg(url) {
      // console.log(url);
      this.previewImgUrl = url;
      this.dialogImg = true;
    },
    expandChange(row, rows) {
      if (rows.includes(row) && rows.filter((v) => v !== row).length) {
        rows
          .filter((v) => v !== row)
          .forEach((v) => {
            this.$refs.expandTable.toggleRowExpansion(v, false);
          });
      }
    },
    getDetail() {
      api
        .get(`/v1/pc/tally/detail?companyId=${this.companyId}`, this.detailPage)
        .then((res) => {
          if (res.code === 200) {
            this.detailList = res.data;
            this.detailTotal = res.total;
          }
        });
    },
    handleDetail(data) {
      // console.log(data);
      this.dialogTitle = data.userName + "的报账详情";
      this.detailPage.user = data.userId;
      this.detailPage.page = 1;
      this.detailPage.status = -1;
      this.detailPage.startTime = this.page.startTime;
      this.detailPage.endTime = this.page.endTime;
      this.value2 = this.value1;
      this.getDetail();
      this.dialogVisible = true;
    },
    search() {
      this.page.page = 1;
      // console.log(this.timeData, "测试");
      this.page.startTime = this.timeData[0]
        ? dayjs(this.timeData[0]).format("YYYY-MM-DD")
        : "";
      this.page.endTime = this.timeData[0]
        ? dayjs(this.timeData[1]).format("YYYY-MM-DD")
        : "";
      this.getList();
    },
    getList() {
      api
        .get(`/v1/pc/tally/statistics?companyId=${this.companyId}`, this.page)
        .then((res) => {
          if (res.code === 200) {
            // console.log(res.data, 111);
            this.tableData = res.data;
            this.total = res.total;
          }
        });
    },
    pageChange(e) {
      this.page.page = e;
      this.getList();
    },
    detailPageChange(e) {
      this.detailPage.page = e;
      this.getDetail();
    },
    searchDetail() {
      // console.log("测试");
      this.detailPage.page = 1;

      this.detailPage.startTime = this.timeDataDetail[0]
        ? dayjs(this.timeDataDetail[0]).format("YYYY-MM-DD")
        : "";
      this.detailPage.endTime = this.timeDataDetail[1]
        ? dayjs(this.timeDataDetail[1]).format("YYYY-MM-DD")
        : "";
      this.getDetail();
    },
  },
};
</script>
<style lang="scss" scoped>
.aic {
  align-items: center;
}
.jcc {
  justify-content: center;
}

.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
}
.expand-row {
  padding-bottom: 20px;
  width: 50%;

  .expand-label {
    padding-bottom: 10px;
    color: #99a9bf;
  }
  .img-box {
    height: 160px;
    width: 160px;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 30px;
    border: 2px solid #99a9bf;
  }
}

.file-box {
  background-color: #66b1ff;
  width: 240px;
  margin-right: 20px;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 8px;
  .file-name {
    color: #fff;
  }
}
.file-box:hover {
  cursor: pointer;
}
</style>


